nav ul ul {
  display: none;
  position: absolute;
  min-width: 140px;
  z-index: 1000;
  left: 0;
  top: 100%;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  background-color: white;
  border-radius: 8px; 
}

nav ul ul li {
  white-space: nowrap;
}

nav li:hover > ul {
  display: block;
}

nav ul ul li a {
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #333333;
}

nav ul ul li a:hover {
  background-color: #f7f7f7;
  color: #000000; 
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  position: relative;
}

nav li:hover {
  z-index: 2000; 
}
